<template>
  <div class="container-fluid mx-0 px-0 border-header   " >

    <div class="bg-header-primary pt-2 text-center mx-0 px-0 pb-1">
      <div class="row mx-0 px-0">
        <div class="col align-self-center">

               <div class="dropdown">
            <button
              class="btn btn-transparent text-white btn-sm"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img src="/flag_en.svg" alt="english flag" class="h-8 w-8" />
              <span class="ffont"> English</span>
              <svg
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path
                  class="heroicon-ui"
                  d="M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z"
                ></path>
              </svg>
            </button>
            <ul
              class="dropdown-menu dropdown-menu-start"
              aria-labelledby="dropdownMenuButton1"
            >
              <li>
                <a class="dropdown-item" href="#">
                  <img src="/flag_en.svg" alt="english flag" class="h-8 w-8" />
                  <span class="ffont"> English</span></a
                >
              </li>
              <li>
                <a class="dropdown-item" href="#">
                  <img src="/flag_fr.svg" alt="french flag" class="h-8 w-8" />
                  <span class="ffont"> French</span></a
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="col-5 align-self-center">
          <router-link
            to="/"
            class="navbar-brand "
            @click.native="scrollToTop"
            href="#"
          >
            <img
              alt="Ominco Group LTD (UK)"
              class="svgcutom logo-custom"
              src="@/assets/logo-01.svg"
          /></router-link>
        </div>
        <div class="col align-self-center">

        </div>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src

export default {
  name: "Home",
  components: {  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style scoped lang="scss">
.bg-header-primary {
  background: #0057B7;
}

@media all and (min-width: 992px) {
  .logo-custom {
    height: 100px;
  }
}
@media all and (max-width: 991px) {
  .logo-custom {
    height: 70px;
  }
}
.Language_title {
  color: rgb(255, 255, 255) !important;
  font-size: 0.75rem !important;
  font-weight: bold;
}
.w-8 {
  width: 1.3rem;
}
.h-8 {
  height: 1.3rem;
}
.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 5rem;
  padding: 0.1rem 0;
  margin: 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.ffont {
  font-size: 13px;
  font-weight: 550;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 0.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.border-header {
  border-bottom: 2px solid #82c341;
}



</style>