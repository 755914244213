<template>
  <div class="container pt-5 bg-white">
    <div class="row justify-content-sm-center d-flex">
      <h2 class="home mt-2">All Hotels Offered by Mudha Hotel</h2>
      <p class="p-copy mt-3 mb-5">
        Book your business trip or family and benefit from a wide selection of
        hotels, from budget to luxury.
      </p>
      <h5 class="home mb-3">All our Destinations</h5>
      <div class="col-sm-auto">
        <div class="profile-card-2 mb-5 bg-light shadow">
          <img
            alt=" "
            class="img img-responsive"
            src="@/assets/profile-2.jpg"
          />

          <div class="profile-name shadow-sm">Somaliland</div>
          <div class="profile-username shadow-sm">Horn of Africa</div>
          <router-link to="/mudhahotelssomaliland" @click.native="scrollToTop">
            <div class="profile-btn">
              <button type="button" class="btn btn-primary btn-sm px-3 shadow">
                EXPLORE
              </button>
            </div></router-link
          >
        </div>
      </div>
      <div class="col-sm-auto">
        <div class="profile-card-2 bg-white shadow">
          <img
            alt=" "
            class="img img-responsive"
            src="@/assets/profile-3.jpg"
          />

          <div class="profile-name shadow-sm">Djibouti-Coming Soon</div>
          <div class="profile-username shadow-sm">
            Stay tuned for something awesome
          </div>
          <div class="profile-btn">
            <button type="button" class="btn btn-primary btn-sm px-3 shadow-lg">
              EXPLORE
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src

export default {
  name: "OurDestinations",
  components: {},
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style scoped lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Alata&display=swap");
.profile-card-2 {
  max-width: auto;

  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);

  overflow: hidden;
  position: relative;

  cursor: pointer;
}

.profile-card-2 img {
  transition: all linear 0.25s;
  border-radius: 10px;
}
.profile-card-2 .profile-name {
  position: absolute;
  left: 35px;
  bottom: 120px;
  font-size: 25px;
  color: #fff;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
  font-weight: 500;
  transition: all linear 0.25s;
}

.profile-card-2 .profile-username {
  position: absolute;
  bottom: 100px;
  left: 35px;
  color: #fff;
  font-size: 13px;
  transition: all linear 0.25s;
}
.profile-card-2 .profile-btn {
  position: absolute;
  bottom: 50px;
  left: 35px;
  color: #fff;
  font-size: 13px;
  transition: all linear 0.25s;
}

.profile-card-2:hover img {
  filter: grayscale(100%);
}

.profile-card-2:hover .profile-name {
  bottom: 130px;
}

.profile-card-2:hover .profile-username {
  bottom: 110px;
}
.profile-card-2:hover .profile-btn {
  bottom: 60px;
}
.home {
  color: #0057b7;
  font-family: "Alata", sans-serif;
}
.p-copy {
  color: #4a4a4a;

  font-size: 0.9375rem;
  line-height: 1.66;
}
</style>