<template>
<div>
  <div class="container-fluid bg-light-custom border-top-custom mt-5 pb-3">
      <div class="container pt-3">

        <footer>
          <div class="bg-light-custom  px-0 mx-0 pb-3 ">
            <div class="row mb-0 text-white   d-inline">
              <router-link to="#" style="text-decoration: none">
                <a class="text-mega-custom">
©2022 Mudhahotels.com. All rights reserved.

 </a>
              </router-link>

              <router-link
                class="text-mega-custom"
                 to="#"
                @click.native="scrollToTop"
              >
 Privacy Policy
              </router-link>

              <router-link
                class="text-mega-custom"
             to="#"
                @click.native="scrollToTop"
              >
          Site Map
              </router-link>

    <router-link
                class="text-mega-custom"
                 to="#"
                @click.native="scrollToTop"
              >
             Faq's
              </router-link>
    <router-link
                class="text-mega-custom"
                 to="#"
                @click.native="scrollToTop"
              >
             Terms
              </router-link>
   <router-link
                class="text-mega-custom"
                 to="#"
                @click.native="scrollToTop"
              >
            Contact Us
              </router-link>


            </div>
          </div>
        </footer>
      </div>
    </div>

</div>
</template>
<script>
export default {
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>
<style lang="scss" scoped>
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.12.1/css/all.min.css");
.fa {
  cursor: pointer;
  font-size: 21px;
  margin: 5px 10px 5px 10px !important;
}

li {
  margin-top: 8px;
  margin-bottom: 8px;
}

input {
  border-radius: 2px !important;
  background: transparent !important;
  color: #ffffff !important;
}

input:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline-width: 0;
  border-color: #ffffff !important;
}

li:first-child {
  font-size: 14px !important;
  font-weight: 500;
}

small {
  font-size: calc(
    12px + (14 - 12) * ((100vw - 360px) / (1600 - 360))
  ) !important;
}

.text-custom {
  color: #0a67ca;
  font-weight: 400 !important;
  font-size: 13px;
}
.text-mega-custom1 {
  line-height: 20px;
  font-size: 13px;
  font-weight: 400;
  color: #0a67ca;
}
.text-mega-custom {
  line-height: 20px;
  font-size: 13px;
  font-weight: 400;
  color: #0057b7;
}
.text-mega-custom:hover {
  line-height: 20px;
  font-size: 13px;
  font-weight: 400;
  color: #073353;
}
.Subscribe {
  background-color: #ffffff !important;
  font-weight: bold;
}

small {
  color: #777 !important;
}
// .text-white {
// color: hsla(0,0%,100%,.8);
// }

.card-1 {
  border-left: 3px solid green !important;
  border-radius: 0;
}

hr.colored {
  height: 0px;
  background-color: #e3e8ec80;
}
.fa {
  padding: calc(10px + (10 - 10) * ((100vw - 360px) / (1600 - 360))) !important;
  font-size: calc(
    15px + (20 - 15) * ((100vw - 360px) / (1600 - 360))
  ) !important;
  width: calc(30px + (38 - 30) * ((100vw - 360px) / (1600 - 360))) !important;
  text-align: center;
  text-decoration: none;
  border-radius: 50px !important;
  margin: 6px !important;
}
a {
  text-decoration: none !important;
}
.fa-facebook {
  background: #3b5998;
  color: white;
}
.fa:hover {
  background-color: transparent !important;
}
.fa-twitter {
  background: #55acee;
  color: white;
}
.fa-instagram {
  background: #3f729b;
  color: white;
}
.fa-linkedin {
  background: #0e76a8;

  color: white;
}
.form-row {
  position: relative;
  left: calc(30px + (50 - 30) * ((100vw - 320px) / (1600 - 320)));
}

@media screen and (max-width: 726px) {
  .form-row {
    position: relative;
    left: 0px !important;
  }
}

@media screen and (max-width: 1143px) {
  .card-0 {
    background: none;
  }
}
.bg-custom-footer {
  // background-color: #4eaf4a
  background-color: #006400;
}
.border-top-custom {
  border-top: 1px dashed #c2b1b1;
}
.border-top-bottom {
  border-bottom: 1px dashed #e0dfdf;
  padding: 50px;
}
.bg-light-custom {
  background-color: rgb(247, 247, 247) !important;
}
.border-top-custom {
  border-top: 1px dashed #c2b1b1;
}
</style>