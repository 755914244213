<template>
  <div>
    <div><HeaderPrimary /></div>
    <div class="home">
      <div class="text-center home bg-white">
        <OurDestinations />
      </div>
    </div>
    <FooterPrimary />
  </div>
</template>
<script>
// @ is an alias to /src
import HeaderPrimary from "../components/Header/HeaderPrimary.vue";
import OurDestinations from "@/components/HomePage/OurDestinations.vue";
import FooterPrimary from "@/components/Footer/FooterPrimary.vue";
export default {
    title: 'Mudha Hotels',
  name: "Home",
  components: {
    HeaderPrimary,
    OurDestinations,
    FooterPrimary,
  },
};
</script>
<style scoped lang="scss">
</style>
